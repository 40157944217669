@import "~antd/dist/antd.less";

.mainSection {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url("../../Assets/Images/login_back.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;

  .rowSection {
    align-items: center;

    .leftHeading {
      margin-top: 10px;

      span {
        color: #ffffff;
        font-size: 32px;
        font-weight: 600;
      }

      p {
        font-size: 14px;
        color: #ffffff;
        width: calc(100% - 150px);
      }

      :global {
        .ant-divider-horizontal {
          background-color: #ffffff;
        }
      }
    }

    .logoImg {
      padding-bottom: 10px;
      border-bottom: 1px solid #ffffff;
    }

    .container {
      box-shadow: 0 0px 10px rgb(0 0 0 / 10%);
      border-radius: 12px;
      background-color: rgb(255 255 255 / 95%);
      width: 480px;
      :global {
        .ant-image {
          display: flex;
          width: 80px;
        }
      }

      .loginForm {
        padding: 5rem 2rem;
        border-radius: 8px;

        .errorMessage {
          color: red;
          text-align: center;
        }

        .loginHeading {
          font-size: 32px;
          font-weight: 600;
          color: #000;

          span {
            font-weight: 600;
            font-size: 28px;
            color: #ffffff;
          }
        }

        .avatarImages {
          display: block;
          background-color: #bfbfbf;
          margin: auto;
        }

        .forminner {
          padding: 0.5rem 0;
        }

        .loginInput {
          width: 100%;
          height: 48px;
          border: 1px solid rgba(2, 12, 75, 0.12);
          border-radius: 8px;
          font-size: 20px;
        }

        :global {
          ::placeholder {
            font-size: 18px;
            letter-spacing: normal;
          }

          .ant-input-affix-wrapper {
            input.ant-input {
              letter-spacing: 4px;
            }
          }

          .ant-form-item-explain-error {
            margin-top: 6px;
          }
        }

        .loginCheck {
          display: flex;
          justify-content: space-around;

          :global {
            .ant-checkbox {
              color: #000;
            }
          }
        }

        .lCheck {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .buttonBlue {
          background-color: #1183e1;
          width: 100%;
          height: 48px;
          font-size: 20px;
          text-transform: capitalize;
          font-weight: 500;
          border-radius: 8px;
          color: #ffffff;

          &:hover {
            box-shadow: 0 0px 10px rgb(0 0 0 / 10%);
            background-color: #1183e1;
            color: #ffffff;
          }
        }
      }
    }
  }
}

@media (max-width: 1023.98px) {
  .mainSection {
    overflow: hidden;

    .rowSection {
      padding: 0 1rem;
      .container {
        width: auto;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .mainSection {
    display: block;
    overflow-x: hidden;
    padding: 10px 20px;

    .rowSection {
      :global {
        .ant-image {
          width: 150px;
        }
      }

      .centerSection {
        text-align: center;
        margin-bottom: 20px;

        .leftHeading {
          p {
            width: auto;
          }
        }
      }

      .container {
        width: 100%;
        padding: 10px;

        .loginForm {
          width: 100%;
          padding: 10px;
        }
      }
    }
  }
}

@primary-color: #1183e1;@heading-color: #3e5569;@text-color: #3e5569;@btn-border-radius-base: 4px;