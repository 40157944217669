.reportSection {
  padding: 30px;
  margin: 30px;
  background-color: #fff;
  .btnDownload {
    width: 100%;
  }

  table {
    margin: 30px 0;
    tr {
      td {
        font-size: 16px;
        font-weight: 600;
        &:nth-child(5) {
          color: #8bc34a;
        }
        &:nth-child(6) {
          color: #ff5722;
        }
        &:nth-child(7) {
          color: #1183e1;
        }
      }
    }
  }
  .reportTime,
  .reportDate {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  .screenSection {
    border: 1px solid #d9d9d9;
    padding: 15px 10px;
    margin: 20px 0;

    .reportTimer {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      display: flex;
    }
  }
  .deleteBtn {
    color: #ff4d4f;
    border-color: #ff4d4f;
    background: #fff;
    width: 32px;
    height: 32px;
    margin: 4px;
    line-height: 32px;
    border: 1px solid;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    padding: 2.4px 0;
    font-size: 16px;
    border-radius: 4px;
    vertical-align: -3px;
    cursor: pointer;
    z-index: 1;
    position: absolute;
    right: 15px;
    margin-top: 10px;
  }
  .imag {
    position: sticky;

    z-index: 1;
  }
  .yoyo{
    
    width: 100px;
  }
  //     .container { position: relative; }
  // .container Image { display: block; }
  // .container .DeleteOutlined  { position: absolute; bottom:0; left:0; }
  :global {
    .ant-select {
      width: 100%;
    }
    .ant-picker {
      width: 100%;
    }
    .ant-table-content {
      overflow: auto;
    }
  }
}

@primary-color: #1183e1;@heading-color: #3e5569;@text-color: #3e5569;@btn-border-radius-base: 4px;