.breadcrumb {
  justify-content: flex-start;
  height: 40px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  a {
    font-size: 16px;
  }
}
.breadcrumbItem {
  margin-left: 20px;
  font-size: 20px;
}
.mainDashboard {
  width: auto;
  margin: 30px;
  overflow: hidden;
  background-image: url(../../Assets//Images/comingSoon.jpg);
  background-size: cover;
  height: 100%;
  //   filter: blur(2px);
  //   -webkit-filter: blur(2px);
  .bgColor {
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.3); /* Black w/opacity/see-through */
    color: white;
    font-weight: bold;
    position: absolute;
    z-index: 2;
    width: 85%;
    padding: 20px;
    text-align: center;
  }
}

@primary-color: #1183e1;@heading-color: #3e5569;@text-color: #3e5569;@btn-border-radius-base: 4px;