// .react__slick__slider__parent {
//     position: relative;
//   }
  
//   .react__slick__slider__parent .slick-prev,
//   .react__slick__slider__parent .slick-next {
//     position: absolute;
//   }
//   .react__slick__slider__parent .slick-prev {
//     color: black;
//     z-index: 1;
//   }
//   .react__slick__slider__parent .slick-next {
//     border-radius: 50%;
//     color: black;
//     z-index: 1;
//   }
  
//   .react__slick__slider__parent .slick-prev:before, .slick-next:before {
//     color: black;
//   }
.seeMore{
  font-size: 28px;
  background-color: white;
  border-radius: 50px;
  border: 1px solid lightgray;
 width: 50px;
padding-left: 10px;
margin-left: auto;
}
@primary-color: #1183e1;@heading-color: #3e5569;@text-color: #3e5569;@btn-border-radius-base: 4px;