@import "~antd/dist/antd.less";

.listView {
  width: auto;
  background-color: #fff;
  padding: 30px;
  overflow: hidden;
  box-shadow: 0 0px 10px rgb(0 0 0 / 10%);

  :global {
    .ant-table {
      overflow-x: auto;
    }
    .anticon-check,
    .anticon-close {
      border-radius: 40px;
      padding: 10px;
      color: #fff;
      margin: 0;
    }
    .anticon-check {
      background-color: #8bc34a;
    }
    .anticon-close {
      background-color: #ff5722;
    }
    .ant-table-placeholder {
      .ant-table-cell {
        width: 100%;
      }
    }
  }
  table {
    width: 100%;
    display: flex;
    flex-direction: column;

    thead {
      tr {
        display: flex;
        th {
          width: 11.1111%;
          &:first-child {
            width: 70px;
          }
        }
      }
    }
    tbody {
      tr {
        display: flex;
        td {
          width: 11.1111%;
          &:first-child {
            width: 70px;
          }
          &:last-child {
            span {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
.searchBox {
  width: 100%;
  margin: 0 0 30px 0px;
  height: 40px;
}
.noData {
  background-color: #ff5722;
  display: block;
  width: 100%;
}
@media (max-width: 767) {
  .searchBox {
    width: 100%;
  }
}
@media (max-width: 580px) {
  .listView {
    padding: 10px;
  }
}

@primary-color: #1183e1;@heading-color: #3e5569;@text-color: #3e5569;@btn-border-radius-base: 4px;