@import "~antd/dist/antd.less";

.main {
  .header {
    background: white;
    height: 60px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;

    .userIcon {
      :global {
        .ant-avatar.ant-avatar-icon {
          background-color: #1183e1;
        }
      }
    }
  }
}

.menu {
  width: auto;
  padding: 30px;
  .avatar {
    display: flex;
    margin: auto;
    margin-bottom: 30px;
    background-color: #1183e1;
    span {
      font-size: 36px;
      font-weight: 600;
    }
  }
  h2 {
    color: #2c3e50;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
  }
  h4 {
    font-size: 16px;
    text-align: center;
  }

  h3 {
    font-size: 16px;
    text-align: center;
  }
  h6 {
    text-align: center;
    color: #2c3e50;
    font-size: 14px;
    font-weight: 400;
  }
  .buttonProfile {
    display: flex;
    align-items: center;
    justify-content: center;
    .btnProfile {
      margin-right: 10px;
    }
  }
}

.breadcrumb {
  padding: 10px;

  a {
    font-size: 16px;
  }
}

.breadcrumbItem {
  margin-left: 20px;
  font-size: 20px;
}
@primary-color: #1183e1;@heading-color: #3e5569;@text-color: #3e5569;@btn-border-radius-base: 4px;