.main {
  width: auto;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 0px 10px rgb(0 0 0 / 10%);
  margin: 10px 30px 30px 30px;
  input {
    height: 48px;
  }
  .formItems {
    margin-bottom: 0;
    :global {
      .ant-form-item {
        margin-bottom: 0;
      }
      .ant-upload-list-item {
        margin-top: 0;
        height: 48px;
      }
    }
  }
  .child {
    display: flex;
    justify-content: space-between;
  }
  .loginInput {
    width: 100%;
    height: 48px;
    line-height: 48px;
    border-radius: 6px;
  }
  .selectInput {
    line-height: 48px;
    border-radius: 8px;
  }
  .buttonBlue,
  .buttonAdd {
    width: 150px;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
  .btnAdd {
    width: 150px;
    margin: 20px 0;
  }
  :global {
    .ant-descriptions-item-container {
      display: block;
      padding: 0 10px;
    }

    .ant-descriptions-row {
      td {
        padding-bottom: 0;
        border: 1px solid #ddd;
        height: 40px;
        line-height: 40px;
      }
    }
  }
  .calButton {
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
  }
}
@media (max-width: 768px) {
  .buttonBlue {
    width: calc(50% - 5px) !important;
  }
}
@media (max-width: 580px) {
  .main {
    margin: 10px;
  }
  .calButton {
    margin-right: 20px;
    margin-left: 20px;
  }
}

@primary-color: #1183e1;@heading-color: #3e5569;@text-color: #3e5569;@btn-border-radius-base: 4px;