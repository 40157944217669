@import "~antd/dist/antd.less";

.mainSection {
  background-color: #fff;
  padding: 30px;
  width: 100%;

  .loader {
    position: absolute;
    left: 53%;
    top: 50%;
    z-index: 1;
  }

  .mainInner {
    width: auto;
    background-color: #fff;
    padding: 1rem;
    overflow: hidden;
    box-shadow: 0 0px 10px rgb(0 0 0 / 10%);

    :global {
      .fc-h-event {
        text-align: center;
        .fc-event-main {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
      }

      .fc-col-header-cell-cushion {
        padding: 10px;
        color: #3e5569;
      }

      .fc-daygrid-day-number {
        background-color: #e7f4ff;
        border-radius: 50px;
        width: 30px;
        height: 30px;
        text-align: center;
        margin: 5px;
      }

      .fc-col-header {
        width: 100%;
      }

      .fc-button-primary {
        background-color: #1183e1;
        border: 1px solid #1183e1;

        &:hover,
        &:active {
          background-color: #fff !important;
          outline: none;
          box-shadow: none;
        }
      }

      .fc-button-primary:not(:disabled).fc-button-active:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }
}
:global {
  #name,
  #title,
  #description {
    margin-bottom: 0;
    font-size: 16px;
  }
}

.searchBox {
  width: 25%;
  margin: 0 0 10px 0px;
  height: 40px;
}
@media (max-width: 768px) {
  table {
    width: 1200px;
  }
  .searchBox {
    width: 100%;
  }
  :global {
    .ant-radio-button-wrapper {
      width: 100%;
      text-align: center;
    }
  }
}
@media (max-width: 580px) {
  .mainSection {
    padding: 10px 0;
  }
}

@primary-color: #1183e1;@heading-color: #3e5569;@text-color: #3e5569;@btn-border-radius-base: 4px;