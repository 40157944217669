@import "~antd/dist/antd.less";

.main {
  width: auto;
  background-color: #fff;
  margin: auto;
  padding: 30px;
  overflow: hidden;
  table {
    tr {
      th {
        background-color: #f1faff;

        a {
          color: #3e5569;
          padding: 10px 0 !important;
        }
      }
    }
  }
}
:global {
  .fc-h-event {
    padding: 1px;
  }

  .fc-col-header-cell-cushion {
    padding: 10px;
    color: #3e5569;
  }

  .fc-daygrid-day-number {
    background-color: #e7f4ff;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    text-align: center;
    margin: 5px;
  }

  .fc-col-header {
    width: 100%;
  }

  .fc-button-primary {

    &:hover,
    &:active {
      background-color: #fff !important;
      color: #1183e1 !important;
      border: 1px solid #1183e1;
      outline: none;
      box-shadow: none;
    }
  }

  .fc-button-primary:not(:disabled).fc-button-active:focus {
    box-shadow: none;
    outline: none;
  }
}
@media (max-width: 580px) {
  .main {
    padding: 10px;
  }
}

@primary-color: #1183e1;@heading-color: #3e5569;@text-color: #3e5569;@btn-border-radius-base: 4px;