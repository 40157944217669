.listTable {
  width: auto;
  background-color: #fff;
  margin: 30px;
  overflow: hidden;
  box-shadow: 0 0px 10px rgb(0 0 0 / 10%);
  table {
    width: 100%;
    display: flex;
    flex-direction: column;

    thead {
      tr {
        display: flex;
        th {
          width: 11.1111%;
        }
      }
    }
    tbody {
      tr {
        display: flex;
        td {
          width: 11.1111%;
        }
      }
    }
  }
}
@media (max-width: 580px) {
  .listTable {
    margin: 10px;
  }
}

@primary-color: #1183e1;@heading-color: #3e5569;@text-color: #3e5569;@btn-border-radius-base: 4px;