@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
.slick-prev:before,
.slick-next:before {
  color: black!important;
  font-size: 30px;
}

.your-table table {
  width: auto;
  min-width: unset !important;
}
.antd-table-custom-class thead th, .antd-table-custom-class tbody td {
  white-space:pre-wrap;
  text-overflow: ellipsis
 }
  .antd-table-custom-class tbody 
  td
  {    white-space: nowrap;
       word-break:break-word;
       word-break: break-all
  }
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 .fc-button-group {
  background-color: rgb(43, 39, 39);
  color: rgb(224, 217, 217);
  
}
.fc-button-group:hover{
  background:none;
  background-color:rgb(224, 217, 217)
}
