.addemployeeSection {
  background-color: #fff;
  width: auto;
  margin: 30px;
  overflow-y: auto;
  .innerSection {
    padding: 0 30px;
    margin: 30px 0;
  }
  .sectionInner {
    margin: auto;
    h1 {
      margin-bottom: 30px;
    }
  }

  .loginInput {
    width: 100%;
    height: 48px;
    line-height: 48px;
    border-radius: 8px;
  }
  .selectInput {
    line-height: 48px;
    border-radius: 8px;
  }
  .buttonBlue {
    width: 150px;
    margin-right: 50px;
    margin-top: 20px;
    &:last-child {
      margin-left: 20px;
    }
  }
  :global {
    .ant-upload-list-text {
      .ant-upload-list-item-name {
        width: 200px;
        white-space: no-warp;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .ant-upload-list-picture {
      .ant-upload-list-item-name {
        width: 200px;
        white-space: no-warp;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .ant-upload-list-item-card-actions-btn {
      display: none;
    }
  }
}
@media (max-width: 768px) {
  .addemployeeSection {
    height: 100%;
    .buttonBlue {
      width: calc(50% - 5px) !important;
      margin-bottom: 10px;
    }
  }
}
@media (max-width: 580px) {
  .addemployeeSection {
    margin: 10px;
    .innerSection {
      padding: 0 10px;
      margin: 10px 0;
    }
  }
}

@primary-color: #1183e1;@heading-color: #3e5569;@text-color: #3e5569;@btn-border-radius-base: 4px;